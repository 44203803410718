import { AngelState, OldUser, User } from '../types'
import { DISPLAYED_MEMBERSHIPS, DISPLAYED_TERMS } from '../const'
import { MembershipsTerm, MembershipsName, MEMBERSHIP_TYPES } from '../types'

export function decomposeMembershipType(membership_type?: MEMBERSHIP_TYPES) {
  if (membership_type === undefined) {
    return {
      membershipPlan: 'trial' as MembershipsName,
      membershipTerm: 'monthly' as MembershipsTerm
    }
  }

  const membershipTypeSplit = membership_type.split('_')
  const parts = [membershipTypeSplit.pop(), membershipTypeSplit.join('_')]

  const membershipPlan = parts.find(part => DISPLAYED_MEMBERSHIPS.includes(part as MembershipsName)) as
    | MembershipsName
    | undefined

  const membershipTerm = parts.find(part => DISPLAYED_TERMS.includes(part as MembershipsTerm)) as
    | MembershipsTerm
    | undefined

  return { membershipPlan, membershipTerm }
}

export function getMembershipMonthlyPlan(membership_name: MembershipsName): MEMBERSHIP_TYPES {
  switch (membership_name) {
    case 'pet_service':
      return MEMBERSHIP_TYPES.PET_SERVICE_MONTHLY
    default:
      return MEMBERSHIP_TYPES.FULL_SERVICE_MONTHLY
  }
}

export function getMembershipYearlyPlan(membership_name: MembershipsName): MEMBERSHIP_TYPES {
  switch (membership_name) {
    case 'pet_service':
      return MEMBERSHIP_TYPES.PET_SERVICE_YEARLY
    default:
      return MEMBERSHIP_TYPES.FULL_SERVICE_YEARLY
  }
}

export function getMembershipTrialPlan(membership_name: MembershipsName): MEMBERSHIP_TYPES {
  switch (membership_name) {
    case 'pet_service':
      return MEMBERSHIP_TYPES.PET_SERVICE_TRIAL
    default:
      return MEMBERSHIP_TYPES.FULL_SERVICE_TRIAL
  }
}

/**
 * @deprecated Need's complete refactor this is just a quick fix from legacy code
 */
export const getUserNavigationRoute = (user: OldUser): { screen: string; params?: any } => {
  const {
    role,
    angel,
    is_accepted,
    has_active_timer,
    has_pending_bookings,
    area_id,
    waitinglist,
    referral,
    area,
    has_outstanding_payments,
    image,
    family
  } = user

  if (user.family && user.current_landing_screen) {
    return { screen: user.current_landing_screen.screen_name }
  }

  let routeAndParams: { screen: string; params?: any }

  if (waitinglist) {
    return { screen: 'WaitingList', params: { area, referral, role } }
  }

  if (angel) {
    const { angelAppointment, current_state, family_id, is_signup_test, is_family_angel } = angel

    const isFamilyAngel = family_id || is_family_angel

    if (area_id === 0 && !isFamilyAngel) {
      return { screen: 'SignUpOutsideService', params: { role } }
    }

    if (!is_accepted && !isFamilyAngel) {
      if (current_state === AngelState.RETIRED) {
        routeAndParams = { screen: 'ProfileAngelUnsubscribe', params: { wasRetired: true } }
      } else if (is_signup_test) {
        if (image) {
          return (routeAndParams = { screen: 'SignUpReadyToFly' })
        }
        routeAndParams = { screen: 'AngelDescriptionScreen' }
      } else if (angelAppointment?.current_state === 'accepted') {
        routeAndParams = { screen: 'AngelTabNavigator' }
      } else {
        routeAndParams = { screen: 'WelcomeBack' }
      }
    } else if (current_state === AngelState.SUSPENDED) {
      routeAndParams = { screen: 'Suspended' }
    } else if (current_state === AngelState.COULD_BOOST) {
      routeAndParams = { screen: 'Boost' }
    } else if (current_state === AngelState.PAUSED) {
      routeAndParams = { screen: 'ProfileAngelUnsubscribe', params: { wasPaused: true } }
    } else {
      routeAndParams = { screen: 'AngelTabNavigator' }
    }
  } else if (has_pending_bookings) {
    routeAndParams = { screen: 'BecomeMember' }
  } else if (has_outstanding_payments) {
    routeAndParams = { screen: 'PaymentListScreen', params: { hasOutstandingPayments: true } }
  } else if (has_active_timer) {
    routeAndParams = { screen: 'ChatInbox' }
  } else if (family?.child_count === 0 || family?.short_bio === '') {
    routeAndParams = { screen: 'FamilyInfoScreen' }
  } else {
    routeAndParams = { screen: 'ParentTabNavigator' }
  }

  return routeAndParams
}

export const getMissingFieldsIndicator = (screen: string, user: User): number => {
  if (user.angel) {
    const { account_number } = user.profile
    const {
      video,
      short_bio,
      education,
      field_of_study,
      tutor,
      driving_license,
      first_aid,
      min_age_children,
      normal_rate,
      extra_rate
    } = user.angel

    if (screen === 'profile') {
      const isLanguageSet = user.languages.length > 0

      const isSkillsSet = [tutor, driving_license, first_aid].find(lang => lang)

      return [
        account_number,
        // video,
        short_bio,
        education,
        field_of_study,
        isLanguageSet,
        isSkillsSet,
        min_age_children
      ].filter(field => field === undefined || field === null || field === '').length
    } else if (screen === 'settings') {
      const isRateChanged = normal_rate !== 7 || extra_rate !== 7
      return [isRateChanged].filter(field => !field).length
    }
  }

  return 0
}
