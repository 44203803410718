import React, { useRef, useState } from 'react'
import { Card, TextInput, CharlyIcon, Video, MissingIndicator, ScreenLayout, NumberInput } from '@charlycares/ui'
import { Box, Button, Heading, HStack, Spinner, Text } from 'native-base'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import {
  useGetAngelsAvgRatesQuery,
  useGetUserQuery,
  useUpdateProfileMutation,
  useUploadAngelVideoMutation
} from '@charlycares/data-access'
import { useFormik } from 'formik'
import { Alert, Keyboard, Linking } from 'react-native'
import {
  links,
  hideAccountNumber,
  useRouter,
  validateIBAN,
  isDev,
  uploadFileBase64,
  browseMedia,
  isValidBSN,
  formatMoney,
  formatMonetaryValue,
  ANGEL_RATE_MIN_AMOUNT,
  ANGEL_RATE_MAX_AMOUNT
} from '@charlycares/shared'

import AngelProfileDetails from './AngelProfileDetails'
import AngelSkills from './AngelSkills'
import { useAlert } from '../../../../hooks'

const ValidationSchema = Yup.object().shape({
  first_name: Yup.string().required(),
  last_name: Yup.string().required(),
  postalcode: Yup.string().min(6).required(),
  street_number: Yup.string().required(),
  phone: Yup.string().min(10).required(),
  birthdate: Yup.string().required(),
  account_number: Yup.string().trim(),
  citizen_service_number: Yup.string().trim().min(8).max(9),
  short_bio: Yup.string().min(200),
  education: Yup.string(),
  field_of_study: Yup.string(),
  first_aid: Yup.boolean(),
  driving_license: Yup.boolean(),
  tutor: Yup.boolean(),
  languages: Yup.object().shape({
    dutch: Yup.boolean(),
    english: Yup.boolean(),
    french: Yup.boolean(),
    german: Yup.boolean(),
    spanish: Yup.boolean(),
    italian: Yup.boolean()
  })
})

export default function AngelProfile() {
  const { t, i18n } = useTranslation()
  const locale = i18n.language.split('_')[0]
  const { navigate } = useRouter()
  const alert = useAlert()

  const { data: user } = useGetUserQuery()
  const [updateProfile, { isLoading, error }] = useUpdateProfileMutation()
  const [updateAngelVideoUrl] = useUploadAngelVideoMutation()

  const [editable, setEditable] = useState(false)
  const [videoSource, setVideoSource] = useState(user?.angel?.video || links.angel.exampleVideo)
  const videoRef = useRef<any>(null)
  const [isVideoUploading, setIsVideoUploading] = useState(false)
  const { data: avgRates } = useGetAngelsAvgRatesQuery()

  const { handleSubmit, handleChange, setFieldValue, resetForm, errors, values, setFieldError } = useFormik({
    validationSchema: ValidationSchema,
    validateOnChange: false,
    initialValues: {
      first_name: user?.profile.first_name || '',
      last_name: user?.profile.last_name || '',
      postalcode: user?.profile.postal_code || '',
      street_number: user?.profile.street_number || '',
      phone: user?.profile.phone || '',
      birthdate: user?.profile.date_of_birth || '',
      street_name: user?.profile.street_name || '',
      city: user?.profile.city || '',
      short_bio: user?.angel?.short_bio || '',
      education: user?.angel?.education || '',
      field_of_study: user?.angel?.field_of_study || '',
      first_aid: Boolean(user?.angel?.first_aid),
      driving_license: Boolean(user?.angel?.driving_license),
      tutor: Boolean(user?.angel?.tutor),
      account_number: '',
      citizen_service_number: '',
      min_age_children: user?.angel?.min_age_children,
      languages: {
        dutch: !!user?.selected_languages.dutch,
        english: !!user?.selected_languages.english,
        french: !!user?.selected_languages.french,
        german: !!user?.selected_languages.german,
        spanish: !!user?.selected_languages.spanish,
        italian: !!user?.selected_languages.italian
      },
      normal_rate: user?.angel?.normal_rate || ANGEL_RATE_MIN_AMOUNT,
      extra_rate: user?.angel?.extra_rate || ANGEL_RATE_MIN_AMOUNT
    },
    onSubmit: async values => {
      Keyboard.dismiss()

      // validate account number
      const newAccountNumber = values.account_number.trim()
      if (newAccountNumber && !validateIBAN(values.account_number)) {
        setFieldError('account_number', t('invalidIBAN'))
        return
      }

      const newCitizenServiceNumber = values.citizen_service_number.trim()
      if (newCitizenServiceNumber && !isValidBSN(newCitizenServiceNumber)) {
        setFieldError('citizen_service_number', t('invalidBSN'))
        return
      }

      try {
        await updateProfile({
          ...values,
          account_number: newAccountNumber || user?.profile.account_number,
          citizen_service_number: newCitizenServiceNumber || ''
        }).unwrap()
      } catch (error) {
        const errorMessage = (error as any)?.data?.message
        alert.show(t('error'), errorMessage?.[Object.keys(errorMessage)[0]]?.[0] || t('updateProfileError'))

        return
      }

      setEditable(false)
    }
  })

  const onCancel = () => {
    Alert.alert(t('profileScreensLeaveThisScreen'), t('profileScreensChangesNotSaved'), [
      { text: t('cancel') },
      {
        text: t('leave'),
        onPress: () => {
          Keyboard.dismiss()
          resetForm()
          setEditable(false)
        }
      }
    ])
  }

  const onVideoUpload = async () => {
    try {
      const video = await browseMedia({
        mediaType: 'video'
      })

      if (video.didCancel || !video.uri || !user?.angel?.video_upload_url) return

      setIsVideoUploading(true)
      // upload to s3
      await uploadFileBase64('PUT', user?.angel?.video_upload_url, video.uri)

      updateAngelVideoUrl()
        .unwrap()
        .then(data => {
          setVideoSource(data.video_url)
        })

      setIsVideoUploading(false)
    } catch (error) {
      isDev && console.log(error)
      Alert.alert(t('error'), t('profileScreensVideoUploadError'))
    }
  }

  return (
    <ScreenLayout
      title={t('profile')}
      headerLeft={
        editable ? (
          <Button
            h="38px"
            p="5px"
            onPress={onCancel}
            variant="ghost"
            _text={{ color: 'primary.400', fontSize: '16px' }}
            _pressed={{
              bg: 'primary.alpha.20'
            }}
            _hover={{
              bg: 'primary.alpha.20'
            }}
          >
            {t('cancel')}
          </Button>
        ) : undefined
      }
      headerRight={
        <Button
          h="38px"
          p="5px"
          onPress={() => (editable ? handleSubmit() : setEditable(true))}
          variant="ghost"
          _text={{ color: 'primary.400', fontSize: '16px' }}
          _pressed={{
            bg: 'primary.alpha.20'
          }}
          _hover={{
            bg: 'primary.alpha.20'
          }}
          isLoading={isLoading || isVideoUploading}
          isLoadingText={t('Updating')}
          spinnerPlacement="end"
          _spinner={{
            color: 'primary.400'
          }}
        >
          {t(editable ? 'save' : 'edit')}
        </Button>
      }
    >
      {/* Profile */}
      <AngelProfileDetails
        avatarUrl={user?.profile.image}
        editable={editable}
        values={values}
        errors={errors}
        handleChange={handleChange}
      />

      {/* Account number */}
      <Card>
        <Box w="100%">
          {!user?.profile.account_number &&
            !(values.account_number.trim() && validateIBAN(values.account_number.trim())) && <MissingIndicator />}

          {editable ? (
            <TextInput
              isReadOnly={!editable}
              label={t('bankAccount')}
              placeholder={
                user?.profile.account_number ? hideAccountNumber(user?.profile.account_number, '*') : t('IBAN')
              }
              value={values.account_number}
              error={errors.account_number}
              onChangeText={handleChange('account_number')}
              returnKeyType="done"
            />
          ) : (
            <>
              <Text fontSize={'14px'} color={'gray.800'}>
                {t('bankAccount')}
              </Text>
              <HStack my="15px" space="10px" alignItems="center">
                <CharlyIcon name="credit-card" size={32} color="black" />
                <Box>
                  <Text fontSize="14px">{hideAccountNumber(user?.profile.account_number)}</Text>
                  <Text fontWeight={300} fontSize="12px">
                    {t('bank/credit')}
                  </Text>
                </Box>
              </HStack>
            </>
          )}
        </Box>
      </Card>

      {/* Citizen service number */}
      {user?.in_beta_projects.includes('citizen_service_number') && (
        <Card>
          <Box w="100%">
            {!user?.profile.citizen_service_number &&
              !(values.citizen_service_number.trim() && isValidBSN(values.citizen_service_number.trim())) && (
                <MissingIndicator />
              )}

            {editable ? (
              <TextInput
                isReadOnly={!editable}
                label={t('citizenServiceNumber')}
                placeholder={user?.profile.citizen_service_number ? user?.profile.citizen_service_number : t('BSN')}
                value={values.citizen_service_number}
                error={errors.citizen_service_number}
                onChangeText={handleChange('citizen_service_number')}
                returnKeyType="done"
              />
            ) : (
              <>
                <Text fontSize={'14px'} color={'gray.800'}>
                  {t('citizenServiceNumberTitle')}
                </Text>
                <HStack my="15px" space="10px" alignItems="center">
                  <CharlyIcon name="icn-profile" size={32} color="black" />
                  <Box>
                    <Text fontSize="14px">{user?.profile.citizen_service_number}</Text>
                    <Text fontWeight={300} fontSize="12px">
                      {t('citizenServiceNumberDescription')}
                    </Text>
                  </Box>
                </HStack>
              </>
            )}
          </Box>
        </Card>
      )}

      <Card>
        <Box w="100%">
          <Heading fontSize={'18px'} fontWeight="600">
            {t('angelHourlyRatesTitle')}
          </Heading>
          <Text mt="10px" fontSize={'15px'}>
            {t('angelHourlyRatesDescription')}
          </Text>

          <Button
            mt="30px"
            variant="outline"
            onPress={() => Linking.openURL(links.earnings[locale as keyof typeof links.earnings])}
          >
            {t('angelHourlyRatesBtn')}
          </Button>

          <Box mt="40px" flexDir="row">
            <Box flex={1}>
              <HStack alignItems="center" space="5px">
                <CharlyIcon name="icn-day" size={32} color="black" />
                <Text fontSize="15px">{t('day')}</Text>
              </HStack>

              <Text fontSize={'12px'} color="gray.700">
                {`${t('profileScreensCompetitiveRate')}  ${formatMoney(
                  avgRates?.['normal_rate' as keyof typeof avgRates]
                )}`}
              </Text>
            </Box>

            <NumberInput
              disabled={!editable}
              changeRate={0.25}
              value={values.normal_rate}
              formatValue={val => formatMonetaryValue(val, locale)}
              min={ANGEL_RATE_MIN_AMOUNT}
              max={ANGEL_RATE_MAX_AMOUNT}
              onChange={val => setFieldValue('normal_rate', val)}
            />
          </Box>

          <Box mt="20px" flexDir="row">
            <Box flex={1}>
              <HStack alignItems="center" space="5px">
                <CharlyIcon name="icn-night" size={'32px'} color="black" />
                <Text fontSize="15px">{t('night')}</Text>
              </HStack>

              <Text fontSize={'12px'} color="gray.700">
                {`${t('profileScreensCompetitiveRate')}  ${formatMoney(
                  avgRates?.['extra_rate' as keyof typeof avgRates]
                )}`}
              </Text>
            </Box>

            <NumberInput
              changeRate={0.25}
              disabled={!editable}
              value={values.extra_rate}
              formatValue={val => formatMonetaryValue(val, locale)}
              min={ANGEL_RATE_MIN_AMOUNT}
              max={ANGEL_RATE_MAX_AMOUNT}
              onChange={val => setFieldValue('extra_rate', val)}
            />
          </Box>
        </Box>
      </Card>

      {/* Upload video & Description */}
      <Card>
        <Box flexDir={'row'} w="100%">
          {/*{!user?.angel?.video && <MissingIndicator />}*/}

          <Box flex={1} bg="gray.100" borderWidth={'1px'} borderColor="gray.200">
            {isVideoUploading ? (
              <Spinner size="lg" color="primary.400" mt="30px" />
            ) : (
              <Video
                key={videoSource}
                resizeMode="contain"
                source={{ uri: videoSource }}
                controls
                paused
                style={{
                  flex: 1
                }}
              />
            )}
          </Box>

          <Box ml="20px" flex={1}>
            <Heading fontSize="18px" fontWeight="400">
              {t('profileScreensPromovideo')}
            </Heading>

            <Button
              bg={editable ? 'secondary.400' : 'gray.400'}
              onPress={onVideoUpload}
              disabled={!editable}
              isLoading={isVideoUploading}
              mt="10px"
              w="95px"
              size="xs"
            >
              {t('upload')}
            </Button>
            <Button
              onPress={() => {
                navigate('VideoPlayer', '/video-player', {
                  source: links.angel.exampleVideo
                })
              }}
              justifyContent="flex-start"
              mt="10px"
              variant="ghost"
              p="0"
              w="115px"
              _text={{ color: 'primary.400', fontSize: '12px' }}
              _pressed={{
                bg: 'transparent',
                opacity: 0.5
              }}
            >
              {t('profileScreensStartExampleVideo')}
            </Button>
          </Box>
        </Box>

        {/* Description */}
        <Box mt="25px" w="100%">
          {!values.short_bio.trim() && <MissingIndicator />}
          <TextInput
            isReadOnly={!editable}
            label={t('description')}
            placeholder={t('angelDescriptionPlaceholder')}
            value={values.short_bio}
            error={errors.short_bio}
            onChangeText={handleChange('short_bio')}
            minHeight="70px"
            variant="filled"
            multiline
            numberOfLines={5}
            maxLength={500}
          />
        </Box>
      </Card>

      {/* Skills */}
      <AngelSkills editable={editable} values={values} setFieldValue={setFieldValue} />

      {/* Education */}
      <Card>
        <Box w="100%">
          {!values.education.trim() && <MissingIndicator />}
          <TextInput
            isReadOnly={!editable}
            label={t('education')}
            value={values.education}
            error={errors.education}
            onChangeText={handleChange('education')}
            placeholder={t('angelEducationPlaceholder')}
            returnKeyType="done"
          />
        </Box>

        <Box w="100%">
          {!values.field_of_study.trim() && <MissingIndicator />}
          <TextInput
            isReadOnly={!editable}
            label={t('fieldOfStudy')}
            value={values.field_of_study}
            error={errors.field_of_study}
            onChangeText={handleChange('field_of_study')}
            placeholder={t('angelFieldOfStudyPlaceholder')}
            returnKeyType="done"
          />
        </Box>
      </Card>
    </ScreenLayout>
  )
}

AngelProfile.navigationOptions = () => ({
  headerShown: false
})
